import request from "../utils/request"

export function login(data) {
    return request({
        url: '/admin/user/login',
        method: 'post',
        data
    })
}

export function getUserList() {
    return request({
        url: '/admin/user/list',
        method: 'post',
    })
}

export function addUser(data) {
    return request({
        url: '/admin/user/add_or_update',
        method: 'post',
        data
    })
}

export function updateUser(data) {
    return request({
        url: '/admin/user/add_or_update',
        method: 'post',
        data
    })
}

export function delUser(data) {
    return request({
        url: '/admin/user/del',
        method: 'post',
        data
    })
}

export function userRegister(data){
    return request({
        url: '/admin/user/register',
        method: 'post',
        data
    })
}

export function userAudit(data){
    return request({
        url: '/admin/user/audit',
        method: 'post',
        data
    })
}

export function getUserAuditList(){
    return request({
        url: '/admin/user/audit_list',
        method: 'post',
    })
}